<div
  class="layout-container-wrap"
  [class.layout-sidenav-collapsed]="
    options.sidenavCollapsed && options.navPos !== 'top'
  "
  [class.layout-navbar-side]="options.navPos === 'side'"
  [class.layout-navbar-top]="options.navPos === 'top'"
  [class.layout-header-above]="options.headerPos === 'above'"
  [class.layout-header-fixed]="options.headerPos === 'fixed'">
  <ng-progress />

  <!-- Header Above -->
  @if (options.showHeader && options.headerPos === 'above') {
    <app-header
      [showBranding]="true"
      (toggleSidenav)="sidenav.toggle()"
      (toggleSidenavNotice)="sidenavNotice.toggle()" />
  }

  <mat-sidenav-container class="layout-container" autosize autofocus>
    <mat-sidenav
      #sidenav
      class="layout-sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()">
      <app-sidebar
        [showToggle]="!isOver"
        [showUser]="options.showUserPanel"
        [showHeader]="options.headerPos !== 'above'"
        [toggleChecked]="options.sidenavCollapsed"
        (toggleCollapsed)="toggleCollapsed()"
        (closeSidenav)="sidenav.close()" />
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over">
      <app-sidebar-notice />
    </mat-sidenav>

    <mat-sidenav-content
      #content
      class="layout-content flex flex-col flex-auto min-w-0">
      @if (options.showHeader && options.headerPos !== 'above') {
        <app-header
          [showToggle]="!options.sidenavCollapsed && options.navPos !== 'top'"
          [showBranding]="options.navPos === 'top'"
          (toggleSidenav)="sidenav.toggle()"
          (toggleSidenavNotice)="sidenavNotice.toggle()" />
      }

      @if (options.navPos === 'top') {
        <app-topmenu />
      }

      <main class="layout-page-content">
        <router-outlet />
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <app-customizer (optionsChange)="updateOptions($event)" />
</div>
