import { inject, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from './settings.service';
import { LOCATION_INITIALIZED } from '@angular/common';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateLangService {
  private readonly _injector = inject(Injector);
  private readonly _translate = inject(TranslateService);
  private readonly _settings = inject(SettingsService);

  async load(): Promise<void> {
    await this._initializeLanguage();
  }

  private async _initializeLanguage(): Promise<void> {
    await this._waitForLocationInitialization();

    const defaultLang = this._getDefaultLanguage();
    this._settings.setLanguage(defaultLang);
    this._translate.setDefaultLang(defaultLang);

    try {
      await lastValueFrom(this._translate.use(defaultLang));
    } catch (e) {
      throw new Error(`Problem with '${defaultLang}' language initialization.`);
    }
  }

  private _waitForLocationInitialization(): Promise<void> {
    return this._injector.get<Promise<void>>(
      LOCATION_INITIALIZED,
      Promise.resolve()
    );
  }

  private _getDefaultLanguage(): string {
    const browserLang = navigator.language;
    const supportedLangRegex = /en-US|de-DE/;
    return supportedLangRegex.exec(browserLang) ? browserLang : 'de-DE';
  }
}
