import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatProgressSpinnerModule, TranslateModule],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingDialogComponent {}
