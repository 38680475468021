import { Directive, HostListener, inject } from '@angular/core';
import { NavAccordionItemDirective } from './nav-accordion-item.directive';

@Directive({
  selector: '[appNavAccordionToggle]',
  exportAs: 'navAccordionToggle',
  standalone: true,
})
export class NavAccordionToggleDirective {
  private readonly _navItem: NavAccordionItemDirective = inject(
    NavAccordionItemDirective
  );

  @HostListener('click', ['$event'])
  onClick() {
    this._navItem.toggle();
  }
}
