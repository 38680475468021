import { Directive, inject, OnDestroy } from '@angular/core';
import { NavAccordionItemDirective } from './nav-accordion-item.directive';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '@core/bootstrap';
import { filter, Subscription } from 'rxjs';

@Directive({
  selector: '[appNavAccordion]',
  exportAs: 'navAccordion',
  standalone: true,
})
export class NavAccordionDirective implements OnDestroy {
  private readonly _router: Router = inject(Router);
  private readonly _menu: MenuService = inject(MenuService);

  private readonly _navItems: NavAccordionItemDirective[] = [];
  private readonly _subscription = new Subscription();

  constructor() {
    this._subscription.add(
      this._router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => this.checkOpenedItems())
    );

    this._subscription.add(
      this._menu.change().subscribe(() => {
        setTimeout(() => this.checkOpenedItems());
      })
    );
  }

  addItem(item: NavAccordionItemDirective): void {
    this._navItems.push(item);
  }

  removeItem(item: NavAccordionItemDirective): void {
    const index = this._navItems.indexOf(item);
    if (index !== -1) {
      this._navItems.splice(index, 1);
    }
  }

  closeOtherItems(openedItem: NavAccordionItemDirective): void {
    this._navItems.forEach(item => {
      if (item !== openedItem) {
        item.expanded = false;
      }
    });
  }

  checkOpenedItems(): void {
    this._navItems.forEach(item => {
      if (item.route && this._router.url.split('/').includes(item.route)) {
        item.expanded = true;
        this.closeOtherItems(item);
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
