/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PreloaderService, SettingsService } from '@core/bootstrap';

@Component({
  selector: 'app-root',
  template: '<router-outlet />',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly _preloader = inject(PreloaderService);
  private readonly _settings = inject(SettingsService);

  constructor(private readonly _renderer: Renderer2) {}

  ngOnInit() {
    this._settings.updateScheme();
    this._settings.updateTheme();
  }

  ngAfterViewInit(): void {
    this._preloader.hide();
  }
}
