import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MenuService } from '@core/bootstrap';
import { AsyncPipe, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { NavAccordionDirective } from './directives/nav-accordion.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NavAccordionItemDirective } from './directives/nav-accordion-item.directive';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { NavAccordionToggleDirective } from './directives/nav-accordion-toggle.directive';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NavAccordionDirective,
    NgxPermissionsModule,
    NavAccordionItemDirective,
    RouterLinkActive,
    MatRippleModule,
    RouterLink,
    NavAccordionToggleDirective,
    MatIconModule,
    AsyncPipe,
    TranslateModule,
    SlicePipe,
  ],
  animations: [
    trigger('expansion', [
      state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: '' })),
      transition(
        'expanded <=> collapsed, void => collapsed',
        animate('225ms cubic-bezier(0.4,0,0.2,1)')
      ),
    ]),
  ],
})
export class SidemenuComponent {
  // Note: Ripple effect makes page flashing on mobile
  @Input() ripple: boolean = false;

  private readonly _menu: MenuService = inject(MenuService);

  public menu$ = this._menu.getAll();

  public buildRoute = this._menu.buildRoute.bind(this._menu);
}
