import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SettingsService } from '@core/bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    AsyncPipe,
    RouterLink,
    MatIconModule,
    TranslateModule,
  ],
})
export class UserComponent {
  private readonly _auth = inject(AuthService);
  private readonly _settings = inject(SettingsService);

  public user$ = this._auth.user$;

  logout() {
    this._auth.logout();
  }

  restore() {
    this._settings.reset();
    window.location.reload();
  }
}
