import {
  Component,
  EventEmitter,
  inject,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AppSettings,
  HeaderPosition,
  NavPosition,
  Scheme,
  Theme,
} from '@core/settings';
import { SettingsService } from '@core/bootstrap';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CdkDrag, CdkDragStart } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DisableControlDirective } from '@shared/directives';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CdkDrag,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSlideToggleModule,
    DisableControlDirective,
    MatDividerModule,
    TranslateModule,
    NgClass,
    UpperCasePipe,
  ],
})
export class CustomizerComponent {
  @ViewChild('customizerPanel') customizerPanel!: TemplateRef<HTMLDivElement>;
  @Output() optionsChange = new EventEmitter<AppSettings>();

  private readonly _settings = inject(SettingsService);
  private readonly _dialog = inject(MatDialog);
  private readonly _fb = inject(FormBuilder);

  private _dragging: boolean = false;
  private _dialogRef?: MatDialogRef<HTMLDivElement>;
  private _formSubscription = Subscription.EMPTY;

  public form = this._fb.nonNullable.group<AppSettings>(this._settings.options);
  public readonly themes: { name: string; id: Theme }[] = [
    { name: 'Prussian Blue', id: 'theme-default' },
    { name: 'Orange (Wheel)', id: 'theme-orange-wheel' },
  ];
  public readonly schemes: { name: string; id: Scheme; icon: string }[] = [
    { name: 'customizer.scheme.auto', id: 'auto', icon: 'bolt' },
    { name: 'customizer.scheme.dark', id: 'dark', icon: 'dark_mode' },
    { name: 'customizer.scheme.light', id: 'light', icon: 'light_mode' },
  ];

  get isHeaderPosAbove() {
    return this.form.get('headerPos')?.value === 'above';
  }

  get isNavPosTop() {
    return this.form.get('navPos')?.value === 'top';
  }

  get isShowHeader() {
    return this.form.get('showHeader')?.value === true;
  }

  setTheme(theme: Theme): void {
    this.form.controls.theme.setValue(theme);
  }

  setScheme(scheme: Scheme): void {
    this.form.controls.scheme.setValue(scheme);
  }

  setHeaderPosition(event: Event, position: HeaderPosition): void {
    if (position === 'above' && (!this.isShowHeader || this.isNavPosTop)) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.form.controls.headerPos.setValue(position);
    }
  }

  setNavigationPosition(event: Event, position: NavPosition): void {
    if (position === 'top' && (this.isHeaderPosAbove || !this.isShowHeader)) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.form.controls.navPos.setValue(position);
    }
  }

  onDragStart(_: CdkDragStart) {
    this._dragging = true;
  }

  openPanel(): void {
    if (this._dragging) {
      this._dragging = false;
      return;
    }

    this._dialogRef = this._dialog.open(this.customizerPanel, {
      maxWidth: '100%',
      height: '100%',
      panelClass: 'customizer-panel',
      position: {
        right: 'right',
      },
    });

    this._dialogRef.afterOpened().subscribe(() => {
      this._formSubscription = this.form.valueChanges.subscribe(_ => {
        this._sendOptions(this.form.getRawValue());
      });
    });

    this._dialogRef.afterClosed().subscribe(() => {
      this._formSubscription.unsubscribe();
    });
  }

  closePanel() {
    this._dialogRef?.close();
  }

  private _sendOptions(options: AppSettings) {
    this.optionsChange.emit(options);
  }
}
