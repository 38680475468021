import { Component, inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorDialogData } from '../error.types';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, TranslateModule, MatButtonModule, MatIconModule],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorDialogComponent {
  public readonly data = inject(MAT_DIALOG_DATA) as ErrorDialogData;

  constructor(
    private readonly _router: Router,
    private readonly _dialogRef: MatDialogRef<ErrorDialogComponent>
  ) {}

  contactSupport(): void {
    this._router
      .navigate(['/help-center/support'], {
        state: {
          errorToSubmit: JSON.stringify(this.data),
        },
      })
      .then(() => {
        this._dialogRef.close();
      })
      .catch(() => {});
  }
}
