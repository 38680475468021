import { GraphQLFormattedError } from 'graphql/error';
import { NetworkError } from '@apollo/client/errors';

export type ErrorDialogData =
  | GraphQLErrorData
  | ApplicationErrorData
  | NetworkErrorData;

export interface GraphQLErrorData {
  type: 'graphql';
  location: string;
  error: GraphQLFormattedError;
}

export interface ApplicationErrorData {
  type: 'application';
  location: string;
  error: Error;
}

export interface NetworkErrorData {
  type: 'network';
  location: string;
  error: NetworkError;
}
