<mat-toolbar>
  @if (showToggle) {
    <button mat-icon-button (click)="toggleSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  }

  @if (showBranding) {
    <app-branding />
  }

  <span class="flex-auto"></span>

  <button mat-icon-button matTooltip="Aktualisieren" (click)="refreshPage()">
    <mat-icon>refresh</mat-icon>
  </button>

  <!-- OUT COMMENT IF WE USE THIS -->
  <!-- <app-translate /> -->

  <app-user />

  <!-- OUT COMMENT IF WE USE THIS -->
  <!-- <app-notification (toggleNotification)="toggleSidenavNotice.emit()" /> -->
</mat-toolbar>
