import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Menu, MenuService } from '@core/bootstrap';
import { filter, Subscription } from 'rxjs';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  TopmenuPanelComponent,
  TopmenuState,
} from './topmenu-panel/topmenu-panel.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.scss'],
  host: {
    class: 'layout-topmenu',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTabsModule,
    NgxPermissionsModule,
    MatButtonModule,
    RouterLinkActive,
    RouterLink,
    NgTemplateOutlet,
    MatMenuModule,
    TopmenuPanelComponent,
    MatIconModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class TopmenuComponent implements OnInit, OnDestroy {
  private readonly _menu = inject(MenuService);
  private readonly _router = inject(Router);

  menu$ = this._menu.getAll();

  buildRoute = this._menu.buildRoute.bind(this._menu);

  menuList: Menu[] = [];
  menuStates: TopmenuState[] = [];

  private _menuSubscription = new Subscription();
  private _routerSubscription = new Subscription();

  ngOnInit(): void {
    this._menuSubscription = this.menu$.subscribe(res => {
      this.menuList = res;
      this.menuStates = this.menuList.map(item => ({
        active: this._router.url.split('/').includes(item.route),
        route: item.route,
      }));
    });
    this._routerSubscription = this._router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(navigationEnd => {
        this.menuStates.forEach(item => (item.active = false));
        const activeRouteIndex = this.menuStates.findIndex(m =>
          navigationEnd.urlAfterRedirects.includes(m.route)
        );
        if (activeRouteIndex !== -1) {
          this.menuStates[activeRouteIndex].active = true;
        }
      });
  }

  ngOnDestroy(): void {
    this._menuSubscription.unsubscribe();
    this._routerSubscription.unsubscribe();
  }
}
