import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private readonly _document = inject(DOCUMENT);

  private readonly _selector = 'splash-screen';

  hide(): void {
    const el = this._getElement();

    if (el) {
      el.addEventListener('transitionend', () => {
        el.className = 'splash-screen-hidden';
      });

      if (!el.classList.contains('splash-screen-hidden')) {
        el.className += ' splash-screen-fade-out';
      }
    }
  }

  private _getElement(): HTMLElement | null {
    return this._document.querySelector(this._selector);
  }
}
