import { Component, inject, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLink,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
    AsyncPipe,
  ],
})
export class UserPanelComponent {
  private readonly _auth = inject(AuthService);
  public user$ = this._auth.user$;

  logout() {
    this._auth.logout();
  }
}
