<mat-tab-group animationDuration="0ms">
  <mat-tab [label]="'sidebar_notice.unread' | translate">
    <ng-container
      *ngTemplateOutlet="notificationTpl; context: { filteredBy: 'unread' }">
    </ng-container>
  </mat-tab>
  <mat-tab [label]="'sidebar_notice.history' | translate">
    <ng-container
      *ngTemplateOutlet="notificationTpl; context: { filteredBy: 'read' }">
    </ng-container>
  </mat-tab>
</mat-tab-group>

<ng-template #notificationTpl let-filteredBy="filteredBy">
  @if (
    (notifications$ | async)?.[filteredBy === 'unread' ? 'unread' : 'read'];
    as notifications
  ) {
    @if (notifications.length) {
      @for (notification of notifications; track notification) {
        <div
          class="flex items-center rounded-xl border gap-4 p-4 mb-4 cursor-pointer hover:bg-surface-container-high"
          (click)="filteredBy === 'read' || markAsRead(notification)"
          (keydown)="filteredBy === 'read' || markAsRead(notification)"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ notification }"
          matRipple>
          <div class="flex items-center justify-center rounded-2xl">
            <mat-icon
              class="icon-size-8"
              [class.text-red-500]="notification.type === 'error'"
              [class.text-green-500]="notification.type === 'success'"
              [class.text-amber-500]="notification.type === 'warning'">
              @if (!notification.icon) {
                @switch (notification.type) {
                  @case ('success') {
                    check_circle
                  }
                  @default {
                    {{ notification.type }}
                  }
                }
              } @else {
                {{ notification.icon }}
              }
            </mat-icon>
          </div>
          <div class="flex-grow w-0">
            <div class="font-semibold">{{ notification.message }}</div>
            <div class="text-sm">
              {{ notification.details }}
            </div>
            <div class="text-sm">
              {{ notification.timestamp | date: 'dd.MM.yyyy HH:mm' }}
            </div>
          </div>
        </div>
      }
    } @else {
      <span class="text-xl">{{
        'sidebar_notice.empty_message' | translate
      }}</span>
    }
  }

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-notification="notification">
      <button mat-menu-item (click)="deleteNotification(notification)">
        <mat-icon>delete</mat-icon>
        <span>{{ 'sidebar_notice.delete' | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-template>
