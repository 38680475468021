import {
  booleanAttribute,
  Directive,
  inject,
  Input,
  OnChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisableControl]',
  standalone: true,
})
export class DisableControlDirective implements OnChanges {
  private readonly _ngControl = inject(NgControl, { optional: true });

  @Input({ transform: booleanAttribute }) disableControl = false;

  ngOnChanges(): void {
    if (this.disableControl) {
      this._ngControl?.control?.disable();
    } else {
      this._ngControl?.control?.enable();
    }
  }
}
