import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService, User } from '@auth0/auth0-angular';
import { NgxRolesService } from 'ngx-permissions';
import { firstValueFrom } from 'rxjs';
import { Menu, MenuService } from './menu.service';

export const MENU: Menu[] = [
  {
    route: 'dashboard',
    name: 'dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  {
    route: 'gyms',
    name: 'gyms',
    type: 'link',
    icon: 'store',
  },
  {
    route: 'customers',
    name: 'customers',
    type: 'link',
    icon: 'person',
  },
  {
    route: 'training-sessions',
    name: 'trainingSessions',
    type: 'link',
    icon: 'fitness_center',
  },
  {
    name: 'helpCenter',
    route: 'help-center',
    type: 'sub',
    icon: 'help_outline',
    children: [
      {
        name: 'guides',
        route: 'guides',
        icon: '',
        type: 'link',
      },
      {
        name: 'support',
        route: 'support',
        icon: '',
        type: 'link',
      },
    ],
  },
  {
    route: 'settings',
    name: 'settings',
    type: 'sidenavSub',
    icon: 'settings',
    children: [
      {
        name: 'account',
        type: 'link',
        icon: 'person',
        route: 'account',
        description: 'menu.settings.account.description',
      },
      {
        name: 'company',
        type: 'link',
        icon: 'business',
        route: 'company',
        description: 'menu.settings.company.description',
      },

      {
        name: 'team',
        type: 'link',
        icon: 'groups',
        route: 'team',
        description: 'menu.settings.team.description',
        // permissions: {
        //   only: ['business-owner-role'],
        // },
      },
    ],
  },
];

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  private readonly _authService = inject(AuthService);
  private readonly _menuService = inject(MenuService);
  private readonly _rolesService = inject(NgxRolesService);
  private readonly _title = inject(Title);

  private readonly _permissionKey =
    'traintogo-management-portal/rolesWithPermissions';

  async load(): Promise<void> {
    try {
      const user = await firstValueFrom(this._authService.user$);
      this._setPermissions(user);
      this._setMenu(MENU);
      this._setTitle(user);
    } catch (error) {
      throw new Error('Error during startup');
    }
  }

  private _setMenu(menu: Menu[]): void {
    this._menuService.addNamespace(menu, 'menu');
    this._menuService.set(menu);
  }

  private _setPermissions(user: User | null | undefined): void {
    if (user) {
      const permissions = user[this._permissionKey] as {
        [name: string]: string[];
      };
      if (permissions) {
        this._rolesService.addRolesWithPermissions(permissions);
      }
    }
  }

  private _setTitle(user: User | null | undefined): void {
    if (user?.['org_name']) {
      this._title.setTitle(`${user['org_name']} - TrainToGo`);
    }
  }
}
