<div
  class="fixed right-8 bottom-8 z-10"
  [matTooltip]="'customizer.button' | translate"
  cdkDrag
  (cdkDragStarted)="onDragStart($event)">
  <button mat-fab (click)="openPanel()">
    <mat-icon
      class="animate-spin-slow"
      aria-label="button with a settings icon"
      fontIcon="settings" />
  </button>
</div>

<ng-template #customizerPanel>
  <div class="flex w-full flex-col overflow-auto">
    <div
      class="flex h-20 min-h-20 flex-row items-center bg-primary px-6 text-white">
      <mat-icon class="text-current icon-size-7" fontIcon="settings" />
      <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
      <button class="ml-auto" mat-icon-button (click)="closePanel()">
        <mat-icon class="text-white" fontIcon="close" />
      </button>
    </div>

    <form [formGroup]="form">
      <div class="flex flex-col p-6">
        <!-- Theme -->
        <div class="text-on-background/70 text-md font-semibold">
          {{ 'customizer.theme' | translate | uppercase }}
        </div>
        <div class="mt-4 grid grid-cols-2 gap-3 sm:grid-cols-3">
          @for (theme of themes; track theme) {
            <div
              class="bg-surface-container-highest flex cursor-pointer items-center justify-center rounded-full px-4 py-3 ring-inset ring-primary"
              [ngClass]="{ 'ring-2': form.controls.theme.value === theme.id }"
              (click)="setTheme(theme.id)"
              (keydown)="setTheme(theme.id)">
              <div
                class="h-3 w-3 flex-0 rounded-full bg-primary"
                [ngClass]="theme.id"></div>
              <div
                class="ml-2.5 truncate font-medium leading-5"
                [ngClass]="{
                  'text-on-background/70':
                    form.controls.theme.value !== theme.id,
                }">
                {{ theme.name }}
              </div>
            </div>
          }
        </div>

        <hr class="my-6" />

        <!-- Scheme -->
        <div class="text-on-background/70 text-md font-semibold">
          {{ 'customizer.scheme' | translate | uppercase }}
        </div>
        <div class="mt-4 grid grid-cols-3 justify-items-center gap-3">
          <!-- Auto -->
          @for (scheme of schemes; track scheme) {
            <div
              class="bg-surface-container-highest flex cursor-pointer items-center rounded-full py-3 pl-5 pr-6 ring-inset ring-primary"
              [ngClass]="{ 'ring-2': form.controls.scheme.value === scheme.id }"
              (click)="setScheme(scheme.id)"
              (keydown)="setScheme(scheme.id)">
              <div class="flex items-center overflow-hidden rounded-full">
                <mat-icon class="icon-size-5" [fontIcon]="scheme.icon" />
              </div>
              <div
                class="ml-2 flex items-center font-medium leading-5"
                [ngClass]="{
                  'text-on-background/70':
                    form.controls.scheme.value !== scheme.id,
                }">
                {{ scheme.name | translate }}
              </div>
            </div>
          }
        </div>

        <hr class="my-6" />

        <!-- Header -->
        <div class="text-on-background/70 text-md font-semibold">
          {{ 'customizer.header' | translate | uppercase }}
        </div>
        <!-- Show header -->
        <div class="mt-4">
          <mat-slide-toggle
            formControlName="showHeader"
            appDisableControl
            [disableControl]="isHeaderPosAbove || isNavPosTop"
            >{{ 'customizer.header.visible' | translate }}</mat-slide-toggle
          >
        </div>

        <div class="mt-4 grid grid-cols-3 gap-3">
          <!-- Above -->
          <div
            class="flex flex-col"
            [class.cursor-pointer]="isShowHeader && !isNavPosTop"
            (click)="setHeaderPosition($event, 'above')"
            (keydown)="setHeaderPosition($event, 'above')">
            <div
              class="flex h-20 flex-col overflow-hidden rounded-md border-2"
              [class.border-primary]="form.controls.headerPos.value === 'above'"
              [ngClass]="
                isShowHeader && !isNavPosTop ? 'hover:opacity-80' : 'opacity-40'
              ">
              <div
                class="flex h-3 items-center bg-surface-container-low pr-2 pl-1 dark:bg-surface-container-high">
                <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                <div class="ml-auto flex items-center justify-end space-x-1">
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                </div>
              </div>
              <div class="flex h-full">
                <div class="w-8 bg-surface-container-lowest border-t">
                  <div
                    class="mx-auto mt-2.5 h-4 w-4 rounded-full bg-on-background/30"></div>
                  <div class="mx-1 mt-2 space-y-1">
                    <div class="h-1 rounded-sm bg-on-background/30"></div>
                    <div class="h-1 rounded-sm bg-on-background/30"></div>
                    <div class="h-1 rounded-sm bg-on-background/30"></div>
                    <div class="h-1 rounded-sm bg-on-background/30"></div>
                  </div>
                </div>
                <div
                  class="flex flex-auto border-t border-l bg-surface-container-low dark:bg-surface-container-high"></div>
              </div>
            </div>
            <div
              class="mt-2 text-center text-md font-medium"
              [ngClass]="
                form.controls.headerPos.value === 'above'
                  ? 'text-primary'
                  : 'text-on-background/70'
              ">
              {{ 'customizer.header.position.above' | translate }}
            </div>
          </div>

          <!-- Fixed -->
          <div
            class="flex cursor-pointer flex-col"
            (click)="setHeaderPosition($event, 'fixed')"
            (keydown)="setHeaderPosition($event, 'fixed')">
            <div
              class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
              [class.border-primary]="
                form.controls.headerPos.value === 'fixed'
              ">
              <div class="w-8 bg-surface-container-lowest">
                <div class="mx-1 mt-1 flex items-center">
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div
                    class="ml-auto h-1 w-1 rounded-full bg-on-background/30"></div>
                </div>
                <div
                  class="mx-auto mt-2.5 h-4 w-4 rounded-full bg-on-background/30"></div>
                <div class="mx-1 mt-2 space-y-1">
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                </div>
              </div>
              <div class="flex flex-auto flex-col border-l">
                <div
                  class="h-3 bg-surface-container-low dark:bg-surface-container-high">
                  <div class="mr-2 flex h-full items-center justify-end">
                    <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  </div>
                </div>
                <div
                  class="flex flex-auto border-t bg-surface-container-low dark:bg-surface-container-high">
                  <div
                    class="flex justify-center items-center w-full text-on-background/30">
                    <div
                      class="flex flex-col justify-center items-center space-y-1">
                      <mat-icon class="text-current" fontIcon="mouse" />
                      <mat-icon
                        class="text-current icon-size-3"
                        fontIcon="arrow_downward" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-2 text-center text-md font-medium"
              [ngClass]="
                form.controls.headerPos.value === 'fixed'
                  ? 'text-primary'
                  : 'text-on-background/70'
              ">
              {{ 'customizer.header.position.fixed' | translate }}
            </div>
          </div>

          <!-- Static -->
          <div
            class="flex cursor-pointer flex-col"
            (click)="setHeaderPosition($event, 'static')"
            (keydown)="setHeaderPosition($event, 'static')">
            <div
              class="flex h-20 overflow-hidden rounded-md border-2 hover:opacity-80"
              [class.border-primary]="
                form.controls.headerPos.value === 'static'
              ">
              <div class="w-8 bg-surface-container-lowest">
                <div class="mx-1 mt-1 flex items-center">
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div
                    class="ml-auto h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div
                    class="ml-0.5 h-1 w-1 rounded-full bg-on-background/30"></div>
                </div>
                <div
                  class="mx-auto mt-2.5 h-4 w-4 rounded-full bg-on-background/30"></div>
                <div class="mx-1 mt-2 space-y-1">
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                </div>
              </div>
              <div class="flex flex-auto flex-col border-l">
                <div
                  class="flex flex-auto bg-surface-container-low dark:bg-surface-container-high">
                  <div
                    class="flex justify-center items-center w-full text-on-background/30">
                    <div
                      class="flex flex-col justify-center items-center space-y-1">
                      <mat-icon class="text-current" fontIcon="mouse" />
                      <mat-icon
                        class="text-current icon-size-3"
                        fontIcon="arrow_downward" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-2 text-center text-md font-medium"
              [ngClass]="
                form.controls.headerPos.value === 'static'
                  ? 'text-primary'
                  : 'text-on-background/70'
              ">
              {{ 'customizer.header.position.static' | translate }}
            </div>
          </div>
        </div>

        <hr class="my-6" />

        <!-- Navigation -->
        <div class="text-on-background/70 text-md font-semibold">
          {{ 'customizer.navigation' | translate | uppercase }}
        </div>
        <!-- Show user panel -->
        <div class="mt-4 flex flex-col">
          <mat-slide-toggle formControlName="showUserPanel">
            {{ 'customizer.navigation.show_user_panel' | translate }}
          </mat-slide-toggle>
        </div>
        <div class="mt-4 grid grid-cols-2 gap-3">
          <!-- Side navigation -->
          <div
            class="flex cursor-pointer flex-col"
            (click)="setNavigationPosition($event, 'side')"
            (keydown)="setNavigationPosition($event, 'side')">
            <div
              class="flex h-24 overflow-hidden rounded-md border-2 hover:opacity-80"
              [class.border-primary]="form.controls.navPos.value === 'side'">
              <div class="w-8 bg-surface-container-lowest">
                <div class="mx-1 mt-1 flex items-center">
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div
                    class="ml-auto h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div
                    class="ml-0.5 h-1 w-1 rounded-full bg-on-background/30"></div>
                </div>
                <div
                  class="mx-auto mt-2.5 h-4 w-4 rounded-full bg-on-background/30"></div>
                <div class="mx-1 mt-2 space-y-1">
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                  <div class="h-1 rounded-sm bg-on-background/30"></div>
                </div>
              </div>
              <div class="flex flex-auto flex-col border-l">
                <div
                  class="h-3 bg-surface-container-low dark:bg-surface-container-high">
                  <div class="mr-2 flex h-full items-center justify-end">
                    <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  </div>
                </div>
                <div
                  class="flex flex-auto border-t bg-surface-container-low dark:bg-surface-container-high"></div>
              </div>
            </div>
            <div
              class="mt-2 text-center text-md font-medium"
              [ngClass]="
                form.controls.navPos.value === 'side'
                  ? 'text-primary'
                  : 'text-on-background/70'
              ">
              {{ 'customizer.navigation.position.side' | translate }}
            </div>
          </div>

          <!-- Top navigation -->
          <div
            class="flex cursor-pointer flex-col"
            [class.cursor-pointer]="isShowHeader && !isHeaderPosAbove"
            (click)="setNavigationPosition($event, 'top')"
            (keydown)="setNavigationPosition($event, 'top')">
            <div
              class="flex h-24 flex-col overflow-hidden rounded-md border-2"
              [class.border-primary]="form.controls.navPos.value === 'top'"
              [ngClass]="
                isShowHeader && !isHeaderPosAbove
                  ? 'hover:opacity-80'
                  : 'opacity-40'
              ">
              <div
                class="flex h-3 items-center px-2 bg-surface-container-low dark:bg-surface-container-high">
                <div class="h-2 w-2 rounded-full bg-on-background/30"></div>
                <div class="ml-auto flex items-center justify-end space-x-1">
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                  <div class="h-1 w-1 rounded-full bg-on-background/30"></div>
                </div>
              </div>
              <div
                class="flex h-3 items-center space-x-1 border-b border-t px-2 bg-surface-container-low dark:bg-surface-container-high">
                <div class="h-1 w-3 rounded-full bg-on-background/30"></div>
                <div class="h-1 w-3 rounded-full bg-on-background/30"></div>
                <div class="h-1 w-3 rounded-full bg-on-background/30"></div>
                <div class="h-1 w-3 rounded-full bg-on-background/30"></div>
              </div>
              <div class="flex flex-auto flex-col overflow-hidden">
                <div
                  class="flex flex-auto bg-surface-container-low dark:bg-surface-container-high"></div>
              </div>
            </div>
            <div
              [ngClass]="
                form.controls.navPos.value === 'top'
                  ? 'text-primary'
                  : 'text-on-background/70'
              "
              class="mt-2 text-center text-md font-medium">
              {{ 'customizer.navigation.position.top' | translate }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
