<div class="layout-user-panel" routerLink="/settings/account">
  <img
    class="layout-user-panel-avatar"
    [src]="(user$ | async)?.picture"
    alt="avatar"
    width="64" />
  <div class="layout-user-panel-info">
    <h4>{{ (user$ | async)?.name }}</h4>
    <h5>{{ (user$ | async)?.email }}</h5>
  </div>
</div>
