import {
  AfterViewInit,
  booleanAttribute,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

enum Border {
  left = 'border-left-width',
  right = 'border-right-width',
}

enum Padding {
  left = 'padding-left',
  right = 'padding-right',
}

@Directive({
  selector: '[appAutosizeInput]',
  standalone: true,
})
export class AutosizeInputDirective implements AfterViewInit, OnDestroy {
  private readonly _element = inject(
    ElementRef
  ) as ElementRef<HTMLInputElement>;
  private readonly _renderer = inject(Renderer2);
  private readonly _ngModel = inject(NgModel, { optional: true });
  private readonly _ngControl = inject(NgControl, { optional: true });

  @Input() extraWidth = 0;
  @Input({ transform: booleanAttribute }) includeBorders = false;
  @Input({ transform: booleanAttribute }) includePadding = true;
  @Input({ transform: booleanAttribute }) includePlaceholder = true;
  @Input() maxWidth = -1;
  @Input() minWidth = -1;
  @Input({ transform: booleanAttribute }) setParentWidth = false;
  @Input({ transform: booleanAttribute }) usePlaceHolderWhenEmpty = true;
  @Input() useValueProperty = false;

  private _destroy$ = new Subject<void>();

  private get _borderWith(): number {
    return this.includeBorders
      ? this._sumStylePropertyWidths(Border.left, Border.right)
      : 0;
  }

  private get _paddingWidth(): number {
    return this.includePadding
      ? this._sumStylePropertyWidths(Padding.left, Padding.right)
      : 0;
  }

  private get _style(): CSSStyleDeclaration {
    return getComputedStyle(this._element.nativeElement, '');
  }

  private get _placeholder(): string {
    return this._element.nativeElement.placeholder;
  }

  private get _value(): string {
    return this._element.nativeElement.value;
  }

  private get _inputWidthIsLessThanMinimum(): boolean {
    return this.minWidth > 0 && this.minWidth > this._inputTextWidth;
  }

  private get _inputWidthIsGreaterThanMaximum(): boolean {
    return this.maxWidth > 0 && this.maxWidth < this._inputTextWidth;
  }

  private get _inputTextWidth(): number {
    return (
      this._getTextWidth(this._input) +
      this.extraWidth +
      this._borderWith +
      this._paddingWidth
    );
  }

  private get _usePlaceholder(): boolean {
    return (
      this.includePlaceholder &&
      this._hasPlaceholder &&
      this._placeholderWidthGreaterThanInputWidth &&
      (this._inputIsEmpty || !this.usePlaceHolderWhenEmpty)
    );
  }

  private get _placeholderWidthGreaterThanInputWidth(): boolean {
    const placeholderWidth = this._getTextWidth(this._placeholder);
    const inputWidth = this._getTextWidth(this._input);

    return placeholderWidth > inputWidth;
  }

  private get _inputIsEmpty(): boolean {
    return this._input.length === 0;
  }

  private get _hasPlaceholder(): boolean {
    return this._placeholder.length > 0;
  }

  private get _input(): string {
    let value = '';
    if (this.useValueProperty) {
      value = this._value;
    } else if (this._ngModel) {
      value = this._ngModel.value as string;
    } else if (this._ngControl) {
      value = this._ngControl.value as string;
    }
    return value || this._value || '';
  }

  private get _nativeElement(): HTMLInputElement {
    return this._element.nativeElement;
  }

  private get _textWidth() {
    const text = this._usePlaceholder ? this._placeholder : this._input;
    return (
      this._borderWith +
      this.extraWidth +
      this._getTextWidth(text) +
      this._paddingWidth
    );
  }

  ngAfterViewInit(): void {
    this._updateWidthWhenModelChanges();
    this._updateWidthWhenControlChanges();
    this._updateWidth();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('input') onInput(): void {
    if (!this._ngModel && !this._ngControl) {
      this._updateWidth();
    }
  }

  private _setWidth(width: number): void {
    const parent = this._renderer.parentNode(this._nativeElement) as ElementRef;

    if (this.setParentWidth) {
      this._renderer.setStyle(parent, 'width', `${width}px`);
    } else {
      this._renderer.setStyle(this._nativeElement, 'width', `${width}px`);
    }
  }

  private _getTextWidth(value: string): number {
    const element = this._renderer.createElement('canvas') as HTMLCanvasElement;
    const context = element.getContext('2d')!;

    const {
      fontStyle,
      fontVariant,
      fontWeight,
      fontSize,
      fontFamily,
      letterSpacing,
    } = this._style;

    context.font = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize} ${fontFamily}`;
    context.letterSpacing = letterSpacing;

    const width = context.measureText(value).width;
    element.remove();
    return width;
  }

  private _updateWidth(): void {
    if (this._inputWidthIsLessThanMinimum) {
      this._setWidth(this.minWidth);
    } else if (this._inputWidthIsGreaterThanMaximum) {
      this._setWidth(this.maxWidth);
    } else {
      this._setWidth(this._textWidth);
    }
  }

  private _updateWidthWhenControlChanges() {
    return this._ngControl?.valueChanges
      ?.pipe(
        tap(() => this._updateWidth()),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  private _updateWidthWhenModelChanges() {
    return this._ngModel?.valueChanges
      ?.pipe(
        tap(() => this._updateWidth()),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  private _sumStylePropertyWidths(...properties: Border[] | Padding[]): number {
    return properties.reduce((sum, property) => {
      const value = this._style.getPropertyValue(property);
      const width = parseInt(value, 10);

      return sum + width;
    }, 0);
  }
}
