import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { Menu, MenuService, MenuSidenavSubChild } from '@core/bootstrap';
import { filter, Subscription } from 'rxjs';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export interface TopmenuState {
  active: boolean;
  route: string;
}

@Component({
  selector: 'app-topmenu-panel',
  templateUrl: './topmenu-panel.component.html',
  standalone: true,
  imports: [
    MatMenuModule,
    NgxPermissionsModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    TranslateModule,
  ],
})
export class TopmenuPanelComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenu, { static: true }) menuPanel!: MatMenu;

  @Input() items: Menu[] | MenuSidenavSubChild[] = [];
  @Input() parentRoute: string[] = [];
  @Input() level = 1;
  @Output() routeChange = new EventEmitter<RouterLinkActive>();

  menuStates: TopmenuState[] = [];

  buildRoute = this._menu.buildRoute.bind(this._menu);

  private _routerSubscription = new Subscription();

  constructor(
    private readonly _menu: MenuService,
    private readonly _router: Router
  ) {}

  ngOnInit() {
    this.items.forEach(item => {
      this.menuStates.push({
        active: this._checkRoute(item),
        route: item.route,
      });
    });
  }

  ngOnDestroy() {
    this._routerSubscription.unsubscribe();
  }

  onRouterLinkClick(rla: RouterLinkActive) {
    this.routeChange.emit(rla);
  }

  onRouteChange(rla: RouterLinkActive, index: number) {
    this.routeChange.emit(rla);

    this._routerSubscription.unsubscribe();
    this._routerSubscription = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        this.menuStates.forEach(item => (item.active = false));
        setTimeout(() => (this.menuStates[index].active = rla.isActive));
      });
  }

  private _checkRoute(item: Menu | MenuSidenavSubChild): boolean {
    if (!item.route) {
      return this._checkChildRoute(item.children);
    } else {
      return this._router.url.split('/').includes(item.route);
    }
  }

  private _checkChildRoute(
    menuItems: Menu[] | MenuSidenavSubChild[] = []
  ): boolean {
    return menuItems.some(child => {
      if (
        this._router.url
          .split('/')
          .filter(p => p)
          .includes(child.route)
      ) {
        return true;
      }
      if (!child.route && child.children) {
        this._checkChildRoute(child.children);
      }
      return false;
    });
  }
}
