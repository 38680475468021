<div class="relative flex flex-col w-full h-full">
  <div class="p-8 pb-6 sm:pb-8">
    <div class="flex flex-col items-center mt-4 sm:mt-0 text-center">
      <!-- Title -->
      <div class="text-xl leading-6 font-medium">
        {{ 'loading_dialog.title' | translate }}
      </div>

      <!-- Message -->
      <div class="text-on-background/70">
        {{ 'loading_dialog.message' | translate }}
      </div>
      <!-- Loading animation -->
      <div class="mt-4">
        <mat-spinner diameter="40" strokeWidth="2"></mat-spinner>
      </div>
    </div>
  </div>
</div>
