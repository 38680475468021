import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrandingComponent } from '../widgets/branding/branding.component';
import { UserPanelComponent } from '../sidebar/user-panel/user-panel.component';
import { SidemenuComponent } from '../sidemenu/sidemenu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    BrandingComponent,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    UserPanelComponent,
    SidemenuComponent,
  ],
})
export class SidebarComponent {
  @Input() showToggle = true;
  @Input() showUser = true;
  @Input() showHeader = true;
  @Input() toggleChecked = false;

  @Output() toggleCollapsed = new EventEmitter<void>();
  @Output() closeSidenav = new EventEmitter<void>();
}
