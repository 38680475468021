<button mat-icon-button [matMenuTriggerFor]="menu">
  <img
    matButtonIcon
    class="avatar rounded-full"
    [src]="(user$ | async)?.picture"
    width="24"
    alt="avatar" />
</button>

<mat-menu #menu="matMenu">
  <button routerLink="/settings/account" mat-menu-item>
    <mat-icon>account_circle</mat-icon>
    <span>{{ 'profile' | translate }}</span>
  </button>
  <!-- OUT COMMENT IF WE NEED THIS -->
  <!-- <button routerLink="/profile/settings" mat-menu-item>
    <mat-icon>edit</mat-icon>
    <span>{{ 'edit_profile' | translate }}</span>
  </button> -->
  <button mat-menu-item (click)="restore()">
    <mat-icon>restore</mat-icon>
    <span>{{ 'restore_defaults' | translate }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'logout' | translate }}</span>
  </button>
</mat-menu>
