import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrim]',
  standalone: true,
})
export class TrimDirective {
  constructor(private readonly _ngControl: NgControl) {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this._ngControl.control?.setValue(value.trim());
  }
}
