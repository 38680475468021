export type NavPosition = 'side' | 'top';
export type Scheme = 'light' | 'dark' | 'auto';
export type Theme = 'theme-default' | 'theme-orange-wheel';
export type HeaderPosition = 'fixed' | 'static' | 'above';

export interface AppSettings {
  navPos: NavPosition;
  scheme: Scheme;
  theme: Theme;
  showHeader: boolean;
  headerPos: HeaderPosition;
  showUserPanel: boolean;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  language: string;
}

export const defaults: AppSettings = {
  navPos: 'side',
  scheme: 'auto',
  theme: 'theme-default',
  showHeader: true,
  headerPos: 'fixed',
  showUserPanel: true,
  sidenavOpened: true,
  sidenavCollapsed: false,
  language: 'de-DE',
};
