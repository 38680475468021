<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (menuItem of menu$ | async; track menuItem; let index = $index) {
    <ng-template
      [ngxPermissionsOnly]="menuItem.permissions?.only!"
      [ngxPermissionsExcept]="menuItem.permissions?.except!">
      @if (menuItem.type === 'link' || menuItem.type === 'sidenavSub') {
        <a
          mat-button
          [routerLink]="buildRoute([menuItem.route])"
          routerLinkActive="active">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{ item: menuItem }" />
        </a>
      }

      @if (menuItem.type === 'extLink') {
        <a mat-button [href]="menuItem.route">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{ item: menuItem }" />
        </a>
      }

      @if (menuItem.type === 'extTabLink') {
        <a mat-button [href]="menuItem.route" target="_blank">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{ item: menuItem }" />
        </a>
      }

      @if (menuItem.type === 'sub') {
        <button
          mat-button
          [matMenuTriggerFor]="submenu.menuPanel"
          routerLinkActive
          #rla="routerLinkActive"
          [class.active]="rla.isActive || menuStates[index].active">
          <ng-container
            [ngTemplateOutlet]="linkTypeTpl"
            [ngTemplateOutletContext]="{ item: menuItem }" />

          <app-topmenu-panel
            #submenu
            [items]="menuItem.children!"
            [parentRoute]="[menuItem.route]"
            [level]="1" />
        </button>
      }
    </ng-template>
  }
</nav>
<mat-tab-nav-panel #tabPanel />

<ng-template #linkTypeTpl let-menuItem="item" let-level="level">
  <mat-icon class="menu-icon">{{ menuItem.icon }}</mat-icon>
  <span class="menu-name">{{ menuItem.name | translate }}</span>
  @if (menuItem.label) {
    <span class="menu-label bg-{{ menuItem.label.color }}">
      {{ menuItem.label.value }}
    </span>
  }

  @if (menuItem.badge) {
    <span class="menu-badge bg-{{ menuItem.badge.color }}">
      {{ menuItem.badge.value }}
    </span>
  }

  @if (!(menuItem.type === 'link' || menuItem.type === 'sidenavSub')) {
    <mat-icon class="menu-caret">
      {{ menuItem.type === 'sub' ? 'arrow_drop_down' : 'launch' }}
    </mat-icon>
  }
</ng-template>
