@if (showHeader) {
  <div class="layout-sidebar-header">
    <app-branding [showName]="!toggleChecked" />

    <span class="flex-auto"></span>

    @if (showToggle) {
      <mat-slide-toggle
        hideIcon
        [checked]="toggleChecked"
        (change)="toggleCollapsed.emit()" />
    } @else {
      <button mat-icon-button (click)="closeSidenav.emit()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
}

<div class="layout-sidebar-main">
  @if (showUser) {
    <app-user-panel />
  }
  <app-sidemenu [ripple]="showToggle" />
</div>
