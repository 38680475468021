import { Component, inject, ViewEncapsulation } from '@angular/core';
import { Notification, NotificationService } from '@shared/services';
import { map } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, DatePipe, NgTemplateOutlet } from '@angular/common';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-sidebar-notice',
  templateUrl: './sidebar-notice.component.html',
  styleUrls: ['./sidebar-notice.component.scss'],
  host: {
    class: 'layout-sidebar-notice',
  },
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    NgTemplateOutlet,
    TranslateModule,
    AsyncPipe,
    DatePipe,
    MatRipple,
  ],
})
export class SidebarNoticeComponent {
  private readonly _notification = inject(NotificationService);

  public notifications$ = this._notification.notifications$.pipe(
    map(notifications => {
      return {
        unread: notifications.filter(n => !n.read),
        read: notifications.filter(n => n.read),
      };
    })
  );

  markAsRead(notification: Notification): void {
    this._notification.markAsRead(notification);
  }

  deleteNotification(notification: Notification): void {
    this._notification.remove(notification);
  }
}
