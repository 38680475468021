import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoadingService } from '@shared/services';
import { genRandomId } from '@shared/utils';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  private readonly _loading = inject(LoadingService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('./assets')) {
      return next.handle(request);
    }

    const randomId = genRandomId();
    this._loading.showLoadingIndicator(randomId);
    return next
      .handle(request)
      .pipe(finalize(() => this._loading.hideLoadingIndicator(randomId)));
  }
}
