<div class="relative flex flex-col w-full h-full">
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-on-background/70" fontIcon="close" />
    </button>
  </div>

  <div
    class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600">
      <mat-icon class="text-current" fontIcon="error" />
    </div>

    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
      <!-- Title -->
      <div class="text-xl leading-6 font-medium">
        {{ 'error_dialog.title' | translate }}
      </div>

      @switch (data.type) {
        @case ('graphql') {
          <div class="text-on-background/70">
            {{ 'error_dialog.graphql.description' | translate }}
          </div>

          @if (
            data.error.extensions?.['code'] ||
            data.error.extensions?.['requestId']
          ) {
            <div
              class="bg-default dark:bg-black dark:bg-opacity-10 p-4 rounded-2xl">
              <div class="leading-6 error-message flex flex-col">
                @if (data.error.extensions?.['code']; as code) {
                  <span>{{ code }}</span>
                }
                <span class="text-md">{{ data.error.message }}</span>
                @if (data.error.extensions?.['requestId']; as requestId) {
                  <span class="text-md">(Request ID: {{ requestId }})</span>
                }
              </div>
            </div>
          }

          <div class="text-on-background/70">
            {{ 'error_dialog.graphql.support_message' | translate }}
          </div>
        }
        @case ('application') {
          <div class="text-on-background/70">
            {{ 'error_dialog.application.description' | translate }}
          </div>

          @if (data.error.name || data.error.message) {
            <div
              class="bg-default dark:bg-black dark:bg-opacity-10 p-4 rounded-2xl">
              <div class="leading-6 error-message flex flex-col">
                @if (data.error.name; as code) {
                  <span>{{ code }}</span>
                }
                <span class="text-md">{{ data.error.message }}</span>
              </div>
            </div>
          }

          <div class="text-on-background/70">
            {{ 'error_dialog.application.support_message' | translate }}
          </div>
        }
        @case ('network') {
          <div class="text-on-background/70">
            {{ 'error_dialog.network.description' | translate }}
          </div>

          @if (data.error?.name || data.error?.message) {
            <div
              class="bg-default dark:bg-black dark:bg-opacity-10 p-4 rounded-2xl">
              <div class="leading-6 error-message flex flex-col">
                @if (data.error?.name; as code) {
                  <span>{{ code }}</span>
                }
                <span class="text-md">{{ data.error?.message }}</span>
              </div>
            </div>
          }

          <div class="text-on-background/70">
            {{ 'error_dialog.network.support_message' | translate }}
          </div>
        }
      }
    </div>
  </div>

  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-surface-container-low dark:bg-surface">
    <button mat-stroked-button [matDialogClose]="undefined">
      {{ 'error_dialog.close' | translate }}
    </button>
    @if (data.type !== 'network') {
      <button mat-flat-button color="primary" (click)="contactSupport()">
        {{ 'error_dialog.support_request' | translate }}
      </button>
    }
  </div>
</div>
