import { NgClass } from '@angular/common';
import { Component, inject, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationConfig } from '@shared/services';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    NgClass,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
  ],
  templateUrl: './confirmation-dialog.component.html',
  styles: [
    `
      .confirmation-dialog-panel {
        @apply md:w-140;

        .mat-mdc-dialog-container {
          .mat-mdc-dialog-surface {
            padding: 0 !important;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent {
  public data = inject(MAT_DIALOG_DATA) as ConfirmationConfig;

  public confirmationInputValue: string = '';

  constructor(public readonly _translate: TranslateService) {}
}
