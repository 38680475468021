export const environment = {
  WS_GRAPHQL_ENDPOINT: 'ws://api.np.traintogo.de/graphql',
  HTTP_GRAPHQL_ENDPOINT: 'https://api.np.traintogo.de/graphql',
  AUDIENCE: 'https://graphql.np.ppm.wellyou.de',
  DOMAIN: 'ppm-wellyou-np.eu.auth0.com',
  CLIENT_ID: 'dKh1qBbawTk6VilMR9rDY2Gym9QU2cYQ',
  STAGE: 'np',
  HOST: 'manage.np.traintogo.de',
  ASSET_URL:
    'https://ppm-assets-np.s3.eu-central-1.amazonaws.com/traintogo-management-portal/assets',
};
