import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavAccordionDirective } from './nav-accordion.directive';
import { MenuType } from '@core/bootstrap';

@Directive({
  selector: '[appNavAccordionItem]',
  exportAs: 'navAccordionItem',
  standalone: true,
})
export class NavAccordionItemDirective implements OnInit, OnDestroy {
  private readonly _nav = inject(NavAccordionDirective);
  private readonly _cdr = inject(ChangeDetectorRef);

  private _isExpanded: boolean = false;

  @Input() route: string = '';
  @Input() itemType: MenuType = 'link';

  @HostBinding('class.expanded')
  @Input()
  get expanded() {
    return this._isExpanded;
  }
  set expanded(value: boolean) {
    this._isExpanded = this.itemType === 'sub' && value;

    this._cdr.markForCheck();

    if (value) {
      this._nav.closeOtherItems(this);
    }
  }

  ngOnInit() {
    this._nav.addItem(this);
  }

  ngOnDestroy() {
    this._nav.removeItem(this);
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
