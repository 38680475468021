import {
  Component,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatSidenav,
  MatSidenavContent,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SettingsService } from '@core/bootstrap';
import { CONFIG } from '@core/config';
import { AppSettings } from '@core/settings';
import { NgProgressComponent } from 'ngx-progressbar';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarNoticeComponent } from './sidebar-notice/sidebar-notice.component';
import { TopmenuComponent } from './topmenu/topmenu.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgProgressComponent,
    HeaderComponent,
    MatSidenavModule,
    SidebarComponent,
    SidebarNoticeComponent,
    TopmenuComponent,
    RouterOutlet,
    CustomizerComponent,
    MatDialogModule,
  ],
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;
  @ViewChild('content', { static: true }) content!: MatSidenavContent;

  private readonly _breakpointObserver = inject(BreakpointObserver);
  private readonly _router = inject(Router);
  private readonly _settings = inject(SettingsService);

  public options = this._settings.options;

  private _layoutChangesSubscription = new Subscription();

  get isOver(): boolean {
    return this._isMobileScreen;
  }

  private _isMobileScreen = false;

  @HostBinding('class.layout-content-width-fix') get contentWidthFix() {
    return (
      this._isContentWidthFixed &&
      this.options.navPos === 'side' &&
      this.options.sidenavOpened &&
      !this.isOver
    );
  }

  private _isContentWidthFixed = true;

  @HostBinding('class.layout-sidenav-collapsed-fix') get collapsedWidthFix() {
    return (
      this._isCollapsedWidthFixed &&
      (this.options.navPos === 'top' ||
        (this.options.sidenavOpened && this.isOver))
    );
  }

  private _isCollapsedWidthFixed = false;

  ngOnInit(): void {
    this._layoutChangesSubscription = this._breakpointObserver
      .observe([
        CONFIG.MOBILE_MEDIA_QUERY,
        CONFIG.TABLET_MEDIA_QUERY,
        CONFIG.MOBILE_MEDIA_QUERY,
      ])
      .subscribe(state => {
        this.options.sidenavOpened = true;

        this._isMobileScreen = state.breakpoints[CONFIG.MOBILE_MEDIA_QUERY];
        this.options.sidenavCollapsed =
          state.breakpoints[CONFIG.TABLET_MEDIA_QUERY];
        this._isContentWidthFixed =
          state.breakpoints[CONFIG.MONITOR_MEDIA_QUERY];
      });

    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        if (this.isOver) {
          void this.sidenav.close();
        }
        this.content.scrollTo({ top: 0 });
      });
  }

  ngOnDestroy() {
    this._layoutChangesSubscription.unsubscribe();
  }

  toggleCollapsed() {
    this._isCollapsedWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this._resetCollapsedState();
  }

  onSidenavClosedStart() {
    this._isContentWidthFixed = false;
  }

  onSidenavOpenedChange(isOpened: boolean) {
    this._isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
    this._settings.setOptions(this.options);
  }

  updateOptions(options: AppSettings): void {
    this.options = options;
    this._settings.setOptions(options);
    this._settings.updateScheme();
    this._settings.updateTheme();
  }

  private _resetCollapsedState(timer = 400) {
    setTimeout(() => this._settings.setOptions(this.options), timer);
  }
}
