import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { ErrorService } from '@shared/services';
import { ApolloError } from '@apollo/client/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly _error = inject(ErrorService);
  private readonly _zone = inject(NgZone);
  private readonly _document = inject(DOCUMENT);

  handleError(error: Error): void {
    if (!(error instanceof ApolloError)) {
      this._zone.run(() => {
        console.error(error);
        this._error.openDialog({
          type: 'application',
          error,
          location: this._document.location.href,
        });
      });
    }
  }
}
