import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class PaginatorI18nService {
  public paginatorIntl = new MatPaginatorIntl();

  constructor(private readonly _translate: TranslateService) {
    this._translate.onLangChange.subscribe((_: LangChangeEvent) =>
      this.getPaginatorIntl()
    );
  }

  getPaginatorIntl(): MatPaginatorIntl {
    this.paginatorIntl.itemsPerPageLabel = this._translate.instant(
      'paginator.items_per_page_label'
    ) as string;
    this.paginatorIntl.previousPageLabel = this._translate.instant(
      'paginator.previous_page_label'
    ) as string;
    this.paginatorIntl.nextPageLabel = this._translate.instant(
      'paginator.next_page_label'
    ) as string;
    this.paginatorIntl.firstPageLabel = this._translate.instant(
      'paginator.first_page_label'
    ) as string;
    this.paginatorIntl.lastPageLabel = this._translate.instant(
      'paginator.last_page_label'
    ) as string;
    this.paginatorIntl.getRangeLabel = this._getRangeLabel.bind(this);

    this.paginatorIntl.changes.next();

    return this.paginatorIntl;
  }

  private _getRangeLabel(
    page: number,
    pageSize: number,
    length: number
  ): string {
    if (length === 0 || pageSize === 0) {
      return this._translate.instant('paginator.range_page_label_1', {
        length,
      }) as string;
    }
    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    /* If the start index exceeds the list length, do not try and fix the end index to the end. */
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex * pageSize;

    return this._translate.instant('paginator.range_page_label_2', {
      startIndex: startIndex + 1,
      endIndex,
      length,
    }) as string;
  }
}
