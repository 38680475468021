<ng-container
  [ngTemplateOutlet]="menuListTpl"
  [ngTemplateOutletContext]="{
    menuList: menu$ | async,
    parentRoute: [],
    level: 0,
    expanded: true,
  }" />

<ng-template
  #menuListTpl
  let-menuList="menuList"
  let-parentRoute="parentRoute"
  let-level="level"
  let-expanded="expanded">
  <ul
    appNavAccordion
    class="layout-sidemenu level-{{ level }}"
    [class.submenu]="level > 0"
    [@expansion]="expanded ? 'expanded' : 'collapsed'">
    @for (menuItem of menuList; track menuItem) {
      <ng-template
        [ngxPermissionsOnly]="menuItem.permissions?.only"
        [ngxPermissionsExcept]="menuItem.permissions?.except">
        <li
          #navItem="navAccordionItem"
          appNavAccordionItem
          class="menu-item"
          routerLinkActive="active"
          [route]="menuItem.route"
          [itemType]="menuItem.type">
          @if (menuItem.type === 'link' || menuItem.type === 'sidenavSub') {
            <a
              class="menu-heading"
              [routerLink]="buildRoute(parentRoute.concat([menuItem.route]))"
              matRipple
              [matRippleDisabled]="!ripple">
              <ng-container
                [ngTemplateOutlet]="linkTypeTpl"
                [ngTemplateOutletContext]="{
                  item: menuItem,
                  level,
                }" />
            </a>
          }

          @if (menuItem.type === 'extLink') {
            <a
              class="menu-heading"
              [href]="menuItem.route"
              matRipple
              [matRippleDisabled]="!ripple">
              <ng-container
                [ngTemplateOutlet]="linkTypeTpl"
                [ngTemplateOutletContext]="{
                  item: menuItem,
                  level,
                }" />
            </a>
          }

          @if (menuItem.type === 'extTabLink') {
            <a
              class="menu-heading"
              [href]="menuItem.route"
              target="_blank"
              matRipple
              [matRippleDisabled]="!ripple">
              <ng-container
                [ngTemplateOutlet]="linkTypeTpl"
                [ngTemplateOutletContext]="{
                  item: menuItem,
                  level,
                }" />
            </a>
          }

          @if (menuItem.type === 'sub') {
            <button
              appNavAccordionToggle
              class="menu-heading menu-toggle"
              matRipple
              [matRippleDisabled]="!ripple">
              <ng-container
                [ngTemplateOutlet]="linkTypeTpl"
                [ngTemplateOutletContext]="{
                  item: menuItem,
                  level,
                }" />
            </button>
          }

          <ng-container
            [ngTemplateOutlet]="menuListTpl"
            [ngTemplateOutletContext]="{
              menuList: menuItem.children,
              parentRoute: parentRoute.concat([menuItem.route]),
              level: level + 1,
              expanded: navItem.expanded,
            }" />
        </li>
      </ng-template>
    }
  </ul>
</ng-template>

<ng-template #linkTypeTpl let-item="item" let-level="level">
  @if (item.icon) {
    <mat-icon class="menu-icon text-current" [class.submenu-icon]="level > 0">{{
      item.icon
    }}</mat-icon>
  } @else {
    <span class="menu-icon" [class.submenu-icon]="level > 0">{{
      item.name | translate | slice: 0 : 1
    }}</span>
  }
  <span class="menu-name">{{ item.name | translate }}</span>

  @if (item.label) {
    <span class="menu-label bg-{{ item.label.color }}">
      {{ item.label.value }}
    </span>
  }

  @if (item.badge) {
    <span class="menu-badge bg-{{ item.badge.color }}">
      {{ item.badge.value }}
    </span>
  }

  @if (!(item.type === 'link' || item.type === 'sidenavSub')) {
    <mat-icon class="menu-caret">
      {{ item.type === 'sub' ? 'arrow_drop_down' : 'launch' }}
    </mat-icon>
  }
</ng-template>
