/**
 * Generates a random alphanumeric string of a specified length.
 * Used for generating a unique name identifier for the alert.
 * @param {number} length - The length of the generated string
 * @returns {string} The generated random alphanumeric string.
 */
export const genRandomId = (length: number = 10): string => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charsetLength = charset.length;

  // Create a Uint8Array to hold random bytes
  const randomValues = new Uint8Array(length);
  window.crypto.getRandomValues(randomValues);

  // Convert random bytes to characters from the charset
  return Array.from(randomValues)
    .map(value => charset[value % charsetLength])
    .join('');
};
