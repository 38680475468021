import { APP_INITIALIZER, Provider } from '@angular/core';
import { StartupService } from '@core/bootstrap/startup.service';
import { TranslateLangService } from '@core/bootstrap/translate-lang.service';
import { MatIconRegistry } from '@angular/material/icon';

const translateLangServiceFactory = (
  translateLangService: TranslateLangService
) => {
  return () => translateLangService.load();
};

const startupServiceFactory = (startupService: StartupService) => {
  return () => startupService.load();
};

const matIconRegistryFactory = (matIconReg: MatIconRegistry) => {
  return () => {
    const defaultFontSetClasses = matIconReg.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter(fontSetClass => fontSetClass !== 'material-icons')
      .concat(['material-symbols-outlined']);
    matIconReg.setDefaultFontSetClass(...outlinedFontSetClasses);
  };
};

export const appInitializerProviders: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: startupServiceFactory,
    deps: [StartupService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: translateLangServiceFactory,
    deps: [TranslateLangService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: matIconRegistryFactory,
    deps: [MatIconRegistry],
    multi: true,
  },
];
