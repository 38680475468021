import { Routes } from '@angular/router';
import { authGuard } from '@core/guards';
import { LayoutComponent } from '@layout/layout.component';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./routes/login/login.routes').then(m => m.routes),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./routes/customers/customers.routes').then(m => m.routes),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./routes/error/error.routes').then(m => m.routes),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./routes/dashboard/dashboard.routes').then(m => m.routes),
      },
      {
        path: 'gyms',
        loadChildren: () =>
          import('./routes/gyms/gyms.routes').then(m => m.routes),
      },
      {
        path: 'training-sessions',
        loadChildren: () =>
          import('./routes/training-sessions/training-sessions.routes').then(
            m => m.routes
          ),
      },
      {
        path: 'help-center',
        loadChildren: () =>
          import('./routes/help-center/help-center.routes').then(m => m.routes),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./routes/settings/settings.routes').then(m => m.routes),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
